import React, { useEffect, useRef } from "react";
import Message from "./message";


const C = ({history, sendFeedback}) => {
  const elementRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      elementRef.current.lastElementChild?.scrollIntoView()
    }, 250)
  }, [history])

  return (
    <div 
      ref={elementRef}
      className="chat-history"
    >
      {history.map((item, index) => {
        if(!item.hidden) return (
          <Message key={index} message={item} sendFeedback={sendFeedback}></Message>
        )})}
    </div>
  );
}

export default C;