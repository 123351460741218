import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import logo from '../images/logo-panda-text.svg'
import './chat.scss'

const C = ({ children }) => {
  return (
    <Container>
        <Row>
          <Col className='logo' xs="3">
            <img src={logo} alt='logo' className='float-left' />
          </Col>
        </Row>
      {children}
    </Container>
  )
}

export default C