import React, { useState } from "react";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import thumbsup from '../../images/hand.thumbsup.svg'
import thumbsdown from '../../images/hand.thumbsdown.svg'

const C = ({message, sendFeedback}) => {
  const [rated, setRated] = useState(null)

  const rateUp = () => {
    if(rated) return
    setRated('up')
    sendFeedback(message.id, 'up')
  }

  const rateDown = () => {
    if(rated) return
    setRated('down')
    sendFeedback(message.id, 'down')
  }

  return (
    <div className="message">
      <strong>{message.name}:</strong> <ReactMarkdown remarkPlugins={[gfm]}>{message.content}</ReactMarkdown>
      {message.role === 'assistant' && message.id !== 'initial' &&
        <div className="assistant-feedback">
          <img className={`up` + (rated === 'up' ? ' rated' : (rated === 'down' ? ' unrated' : ''))} src={thumbsup} alt="Thumbs up" onClick={() => rateUp()}></img>
          <img className={`down` + (rated === 'down' ? ' rated' : (rated === 'up' ? ' unrated' : ''))} src={thumbsdown} alt="Thumbs up" onClick={() => rateDown()}></img>
        </div>
      }
    </div>
  );
}

export default C;