import React from 'react';
import Layout from './layouts/chat'
import Chat from './modules/chat'
import { ToastContainer } from 'react-toastify'

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css'


function App() {
  return (
    <div className="App">
      <ToastContainer autoClose={2000} />
      <Layout>
        <Chat></Chat>
      </Layout>
    </div>
  );
}

export default App;
