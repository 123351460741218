import { functions } from './firebase'
import { httpsCallable } from 'firebase/functions'
import { subscribeOne } from './db'

const collectionName = 'chat-interaction'

const chatInteraction = async (inputData) => {
  const call = httpsCallable(functions, 'chatInteraction')
  const { data } = await call(inputData)
  return data
}

const chatSaveMessageFeedback = (data) => {
  const call = httpsCallable(functions, 'chatSaveMessageFeedback')
  return call(data)
}

const chatInteractionSubscribe = (id, mutationCallback) => subscribeOne(collectionName, id, mutationCallback)


export {
  chatInteraction,
  chatInteractionSubscribe,
  chatSaveMessageFeedback
}
