import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { v4 as uuid } from 'uuid';
import { Col, Input, Row } from "reactstrap";
import { toast } from 'react-toastify'

import Loader from "../../components/loader";
import Outline from "../../components/outline";
import History from "./history";
import send from "../../images/send.svg";
import trash from "../../images/trash.svg";
import "./index.scss"
import { chatInteraction, chatSaveMessageFeedback } from "../../api/chat";


const initialHistory = [
  {
    id: 'initial',
    role: 'assistant',
    name: "ASSISTANT",
    content: "Hello, how can I help you?"
  }
]

const models = [
  { name: 'gpt-3.5-turbo', id: 'gpt-3.5-turbo' },
  { name: 'EarkickChatBot', id: 'EarkickChatBot'}
]

const C = () => {
  const [model, setModel] = useState(models[0].id)
  const [userInputState, setUserInputState] = useState('')
  const [systemMessageInputState, setSystemMessageInputState] = useState("You are a virtual mental health coach called Earkick")
  const [history, setHistory] = useState(initialHistory)
  const [temperature, setTemperature] = useState(0.9)
  const [maxOutput, setMaxOutput] = useState(512)
  const [loading, setLoading] = useState(false)
  const [chatId, setChatId] = useState(uuid())
  const textInput = useRef(null)

  const appendHistory = (message) => {
    const messages = [...history, message]
    return setHistory(messages)
  }

  const handleChatBot = async () => {
    setLoading(true)
    const messages = [
      {
        role: 'system',
        content: systemMessageInputState
      },
      ..._.map(history, (m) => { return { role: m.role, content: m.content } })
    ]

    const {data, error} = await chatInteraction({ 
      messages,
      temperature,
      max_tokens: maxOutput,
      model
    })
    
    if(data) {
      await appendHistory({...data.choices[0].message, name: 'ASSISTANT', role: 'assistant', id: uuid()})
    }

    if(error) {
      console.log('Error in chat interaction', error)
      toast.error('Chat bot returned an error')
    }

    setLoading(false)
    textInput.current.focus()
  }

  useEffect(() => {
    const lastMessage = _.last(history)

    if(lastMessage.role === 'user') {
      handleChatBot()
    }

  }, [history])

  useEffect(() => {
    if(!loading) {
      textInput.current.focus()
    }
  }, [loading])

  const processInput = async () => {
    if(userInputState.trim() === '') return

    const newMessage = {content: userInputState, name: 'USER', role: 'user', id: uuid()}
    const messages = [...history, newMessage]
    setHistory(messages)
    await appendHistory(newMessage)
    setUserInputState('')
  }

  const onEnterPress = (e) => {
    if(e.keyCode === 13 && e.metaKey === true) {
      e.preventDefault();
      processInput()
    } else if(e.keyCode === 13 && e.ctrlKey === true) {
      e.preventDefault();
      processInput()
    }
  }

  const modelSelected = (e) => {
    console.log('model selected', e.target.value)
    setModel(e.target.value)
  }
  
  const sendFeedback = (messageId, feedback) => {
    const data = {
      chat_id: chatId,
      feedback,
      max_tokens: maxOutput,
      message_id: messageId,
      messages: JSON.stringify(history),
      system: systemMessageInputState,
      model,
      temperature,
      created_at: (new Date()).toISOString()
    }
    chatSaveMessageFeedback(data)
  }

  const clearHistory = () => {
    setHistory(initialHistory)
    setChatId(uuid())
  }

  return (
    <Row className="earkick-chat">
      <Col xs="3">
        <h2>System</h2>
        <Outline>
        <Input 
            disabled={loading}
            autoFocus
            innerRef={textInput}
            value={systemMessageInputState}
            onChange={(e) => setSystemMessageInputState(e.target.value)}
            type="textarea" 
            className="system-input-text"
          ></Input>
        </Outline>
      </Col>

      <Col xs="6">
        <h2>Chat <img src={trash} alt="trash" className="trash-button" onClick={clearHistory} /></h2>
        
        <Outline>
          <History history={history} sendFeedback={sendFeedback}></History>
        </Outline>
        <Row className="user-input-row">
          <Col xs="10">
            <Input 
              disabled={loading}
              autoFocus
              innerRef={textInput}
              value={userInputState}
              onChange={(e) => setUserInputState(e.target.value)}
              onKeyDown={onEnterPress}
              type="textarea" 
              className="user-input-text"
            ></Input>
          </Col>
          <Col xs="2">
            {!loading && 
              <img src={send} alt="send" className="send-button" onClick={processInput} />
            }
            {loading &&
             <Loader></Loader> 
            }
          </Col>
        </Row>
      </Col>

      <Col xs="3">
        <h2>Model</h2>
        <Input type="select" onChange={modelSelected}>
          {models.map((model, index) => (
            <option key={index} value={model.id}>{model.name}</option>
          ))}
        </Input>

        <h3>Temperature</h3>
        <span>{temperature}</span>
        <Input type="range" name="temperature" min="0" max="1" step="0.1" value={temperature} 
          onChange={(e) => setTemperature(parseFloat(e.target.value))}
        />

        <h3>Max Output</h3>
        <span>{maxOutput}</span>
        <Input type="range" name="max-output" min="128" max="2048" step="128" value={maxOutput} 
          onChange={(e) => setMaxOutput(parseInt(e.target.value))}
        />
      </Col>
    </Row>
  );
}

export default C;