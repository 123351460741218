import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import config from './firebase.json'

const app = initializeApp(config)
const db = getFirestore(app)
const functions = getFunctions()

export {
  app,
  db,
  functions
}
